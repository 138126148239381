export function formatDate(date = new Date()) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
}

export function addDays({date = new Date(), days = 0}) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function parseDateTime(dateString, timeString = '00:00') {
    const [day, month, year] = dateString.split('/');
    const [hours, minutes] = timeString.split(':');
    return new Date(year, month - 1, day, hours, minutes);
}

export function isFunction(fn) {
    return typeof fn === 'function';
}

export function formatCurrency(value) {
    if (value === null || value === undefined || value === '' || isNaN(value)) value = 0;
    value = parseFloat(value).toFixed(2);
    // if is negative number then add '-' sign
    if (value < 0) {
        value = value * -1;
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '-';
    } else {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

}
export function formatCurrencyIL(value) {
    if (value === null || value === undefined || value === '' || isNaN(value)) value = 0;
    value = parseFloat(value).toFixed(2);
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "\xa0₪";
}
export function formatMinusCurrencyIL(value) {
    if (value === null || value === undefined || value === '' || isNaN(value)) value = 0;
    value = parseFloat(value).toFixed(2);
    // add minus sign before the number
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "-" + "\xa0₪";
}
//format a date
export function formatDateOld(_date = null) {

    const date = _date ? new Date(_date) : new Date();

    // if invalid date
    if (date == 'Invalid Date') return;

    if (date.getTime() == -62135605254000) {
        return;
    }


    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');

}
export function formatDateTime(_date = null) {

    const date = _date ? new Date(_date) : new Date();

    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    let hour = '' + date.getHours();
    let minute = '' + date.getMinutes();
    let second = '' + date.getSeconds();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    if (hour.length < 2) hour = '0' + hour;
    if (minute.length < 2) minute = '0' + minute;
    if (second.length < 2) second = '0' + second;

    return [day, month, year].join('/') + ' ' + [hour, minute, second].join(':');
}

export function formatReadMore(text, length = 5, space = false) {
    if (!text) return '';
    if (text.length > length) {
        let lastSpace = space ? text.lastIndexOf(' ', length) : length;
        return text.substring(0, lastSpace) + '\xa0...';
    }
    return text;
}

export function formatMonth(_date = null) {
    const date = _date ? new Date(_date) : new Date();

    let month = '' + (date.getMonth() + 1);
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;

    return [month, year].join('/');
}

export function formatPercent(value) {
    if (value === null || value === undefined || value === '' || isNaN(value)) value = 0;
    value = parseFloat(value).toFixed(2);
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
}
export function isArray(value) {
    return Array.isArray(value);
}