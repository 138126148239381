import React from 'react';
import { useState, useEffect, useTransition } from 'react';

// material-ui
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Stack,
    Box,
    LinearProgress,
    Typography,
    Card,
    Divider,
} from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { useTheme } from '@mui/material/styles';
import LoadingData from '../../components/LoadingData';
import StatusFilter from './StatusFilter';
import Row from './Row';
import Footer from './Footer';
import Header from './Header';
import { isArray } from '../../func';




const GenericTable = ({ columns, data, loading, height, tableHeight, customHeight, title, noData, header, footer, statuses, statusBarHandleChange, actions, counter, pagination, innerPagination, rowsPerPage, page, setPage, titleBgColor, titleColor, onRowClick, setPageWhenDataChanged }) => {

    const theme = useTheme();

    const [isPending, startTransition] = useTransition();
    const [dataToShow, setDataToShow] = useState([]);
    const [isStart, setIsStart] = useState(true);

    const [fillRow, setFillRow] = useState(null);

    const [isDense, setIsDense] = useState(true);

    const [heights, setHeights] = useState({});

    const [innerPage, setInnerPage] = useState(0);

    useEffect(() => {
        const defaultHeight = {
            container: window.innerHeight * 0.88,
            tableContent: 0.8,
            header: 0.05,
            footer: 0.5,
        }
        if (customHeight) {
            const newHeights = {
                ...defaultHeight,
                ...customHeight,
                container: customHeight.container ? customHeight.container <= 1 ?
                    customHeight.container * window.innerHeight : customHeight.container :
                    defaultHeight.container,
            }
            setHeights({ ...defaultHeight, ...newHeights });
        } else if (height) {
            setHeights({ ...defaultHeight, container: height, tableContent: tableHeight ? tableHeight : 0.8 });
        } else {
            setHeights(defaultHeight);
        }
    }, [height, customHeight, tableHeight]);


    useEffect(() => {
        setIsStart(false);
        startTransition(() => {
            if (innerPagination && isArray(data)) {
                setDataToShow(data.slice(innerPage * rowsPerPage, (innerPage + 1) * rowsPerPage));
            } else {
                setDataToShow(data);
            }
        });
    }, [data, innerPage, rowsPerPage, startTransition, innerPagination]);

    useEffect(() => {
        if (innerPagination && isArray(data) && (setPageWhenDataChanged || data.length < (rowsPerPage * (innerPage + 1)))) {
            setInnerPage(0);
        }
    }, [data, innerPagination, setPageWhenDataChanged]);


    const count = () => {
        if (innerPagination && isArray(data)) return data.length;
        if (!counter) return false;
        if (typeof counter === 'boolean' && counter) return dataToShow.length || 0;
        return counter;
    }

    const countForPagination = () => {
        if (innerPagination && isArray(data)) return data.length;
        if (!pagination) return 0;
        if (!counter) return dataToShow ? dataToShow.length : 0;
        return Number(counter);
    }



    return (
        <Card elevation={0} sx={{ boxShadow: theme.customShadows.z2, borderRadius: 3, height: heights.container }}>
            {(statuses || title || actions) && <StatusFilter
                count={countForPagination()}
                statuses={statuses}
                statusBarHandleChange={statusBarHandleChange}
                title={title}
                isData={dataToShow && dataToShow.length > 0}
                actions={actions}
                height={heights.header} />}
            {header && <Header
                header={header}
            />}
            <TableContainer sx={{
                height: heights.tableContent //height
                // ? tableHeight
                // : counter
                //     ? window.innerHeight * ((dataToShow && dataToShow.length > 0 && (statuses || title)) ? 0.75 : 0.80)
                //     : null
            }}>
                {loading && <LoadingData height={1} />}
                {(dataToShow && dataToShow.length === 0 && !loading) && <Stack direction="column" alignItems="center" spacing={1} justifyContent="center"
                    sx={{ width: 1, height: 1 }}>
                    {noData}
                    {!noData && <SearchOffIcon color='secondary' sx={{ fontSize: counter ? 75 : 50 }} />}
                    {!noData && <Typography variant={counter ? "h1" : "h3"} component='p' sx={{ color: theme.palette.text.secondary }}>
                        לא נמצאו {title ? title : "נתונים"}
                    </Typography>}
                </Stack>}
                {(dataToShow && dataToShow.length > 0 && !loading) && <Table stickyHeader size="small">

                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell key={index} sx={{ bgcolor: titleBgColor, color: titleColor }}>
                                    {isFunction(column.label) ? column.label(setDataToShow) : column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {(isPending || isStart) && <TableRow>
                            <TableCell colSpan={1000}>
                                <Box sx={{ width: '50%', m: "50px auto" }}>
                                    <LinearProgress />
                                </Box>
                            </TableCell>
                        </TableRow>}
                        {(dataToShow && !isPending && !isStart) && dataToShow.map((row, index) => {
                            return (
                                <Row key={index} index={index} row={row} columns={columns} fillRow={fillRow} setFillRow={setFillRow} isDense={isDense} statuses={statuses} onRowClick={onRowClick} />
                            )
                        })}
                    </TableBody>

                </Table>}
                {footer}
            </TableContainer>
            <Divider />
            {(pagination || innerPagination) && <Box sx={{ height: heights.footer }} >
                <Footer
                    // height={1}
                    count={count()}
                    countForPagination={countForPagination()}
                    rowsPerPage={rowsPerPage}
                    page={innerPagination ? innerPage : page}
                    setPage={innerPagination ? setInnerPage : setPage}
                    isDense={isDense}
                    setIsDense={setIsDense}
                />
            </Box>}
        </Card>
    )
}

GenericTable.defaultProps = {
    loading: false,
    height: null,
    tableHeight: 0.65,
    title: null,
    noData: null,
    header: null,
    footer: null,
    statuses: null,
    statusBarHandleChange: null,
    actions: null,
    counter: null,
    pagination: false,
    rowsPerPage: 50,
    page: 0,
    setPage: null,
    titleBgColor: null,
    titleColor: null,
    onRowClick: null,
    customHeight: null,
    setPageWhenDataChanged: true
}

export default GenericTable;

/* statuses example = [
    { key: 1, label: "פעיל", color: "success" },
] */


// check if is function
function isFunction(value) {
    return typeof value === 'function';
}
