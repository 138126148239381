import { Button, ButtonGroup, Stack, Typography } from '@mui/material';
import React from 'react';
import Lottery from './Lottery';
import Context from '../../context';

const SumData = ({ data, getData }) => {

    const { popup } = React.useContext(Context)
    const [sum, setSum] = React.useState({
        nedarim: {
            count: 0,
            sum: 0
        },
        yemot: {
            count: 0,
            sum: 0
        },
    });

    React.useEffect(() => {
        if (data && data !== "loading") {
            const nedarim = data.filter(item => item.mekorHarshama === "nedarimPlus")
            const yemot = data.filter(item => item.mekorHarshama === "ימות המשיח")
            setSum({
                nedarim: {
                    count: nedarim.length,
                    sum: nedarim.reduce((acc, item) => acc + item.dakot, 0)
                },
                yemot: {
                    count: yemot.length,
                    sum: yemot.reduce((acc, item) => acc + item.dakot, 0)
                },
            })
        }
    }, [data])
    return (
        <Stack direction="row" spacing={4} justifyContent="space-between">
            <Stack direction="row" spacing={4} justifyContent="center">

                <Typography variant="h6">נדרים:<b> {sum.nedarim.sum}</b> דקות, {sum.nedarim.count} משתמשים</Typography>
                <Typography variant="h6">טלפון:<b> {sum.yemot.sum}</b> דקות, {sum.yemot.count} משתמשים</Typography>
                <Typography variant="h6">סה"כ:<b> {sum.yemot.sum + sum.nedarim.sum}</b> דקות, {sum.yemot.count + sum.nedarim.count} משתמשים</Typography>

            </Stack>
            <ButtonGroup variant="outlined">
                <Button color="primary" onClick={() => getData()}>רענון</Button>
                <Button color="primary" onClick={() => popup({
                    title: "הגרלה",
                    content: <Lottery users={data} />,
                })}>בצע הגרלה</Button>
            </ButtonGroup>
        </Stack>
    )
}

export default SumData