import React from 'react';
import { Stack, Typography } from '@mui/material';

const SumData = ({ data }) => {
    const [sum, setSum] = React.useState({
        phone: 0,
        sms: 0,
    });
    React.useEffect(() => {
        if (data && data !== "loading") {
            setSum({
                phone: data.filter(item => item.tizkoretType == "phone").length,
                sms: data.filter(item => item.tizkoretType == "sms").length,
                // sms: data.reduce((acc, item) => acc + item.sms, 0),
            })
        }
    }, [data])
    return (
        <Stack direction="row" spacing={4} justifyContent="center">
            <Typography variant="h6">טלפון:<b> {sum.phone}</b> תזכורות</Typography>
            <Typography variant="h6">סמס:<b> {sum.sms}</b> תזכורות</Typography>
            <Typography variant="h6">סה"כ:<b> {sum.phone + sum.sms}</b> תזכורות</Typography>
        </Stack>
    )
}

export default SumData