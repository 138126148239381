import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { GoogleAuthProvider, getAuth, signInWithPopup,  } from "firebase/auth";

import GoogleIcon from '@mui/icons-material/Google';
import { Avatar, Button, Grid, Typography, Paper, Snackbar, Alert } from "@mui/material";
import Context from "../../context";
import { app } from "../../firebase/firebase-sdk"



const Login = () => {

    const [error, setError] = useState(null);
    const { restartUser } = React.useContext(Context)

    const nav = useNavigate();


    const login = () => {
        const a = app;
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        return signInWithPopup(auth, provider)//.then((result) => result)
    }

    React.useEffect(() => {
        const auth = getAuth();
        auth.signOut().then(() => {
            console.log("User signed out successfully");
        }).catch((error) => {
            console.log("Error signing out user:", error);
        });
    }, [])

    return (
        <Grid container component="main" sx={{
            height: "100vh",

            backgroundImage: `url(/bgLogin.jpg)`,
            // opacity: 0.1,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // filter: "brightness(0.5)"
        }} >
            <Grid
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                    height: "60vh",
                    width: "50vh",
                    borderRadius: 15,
                    // background: `rgba(0, 0, 0,0)`,
                    // opacity: 1,
                }}
                item
                component={Paper}
                elevation={20}
                square

            >

                <Avatar src='/logo192.jpg' sx={{ bgcolor: 'gray', width: 200, height: 200, mb: 5 }} />

                <Typography component="h1" variant="h3">
                    ברוך הבא!!
                </Typography>

                <Button
                    sx={{ width: '70%' }}
                    endIcon={<GoogleIcon />}
                    size="large"
                    onClick={() => login()
                        .then((user) => {
                            console.log(user)
                            restartUser(user.user)
                            nav('/manage-daka')
                        })
                        .catch((error) => {
                            setError(true)
                        })
                    }
                    variant="contained" >
                    כניסה עם חשבון גוגל
                </Button>


            </Grid>
            <Snackbar
                open={error}
                autoHideDuration={10000}
                onClose={() => setError(null)}
            >
                <Alert
                    onClose={() => setError(null)}
                    severity='error'
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    <Typography variant="h4">משתמש לא מורשה</Typography>
                    <Typography variant="caption">לפרטים צור קשר עם מנהל האתר</Typography>
                </Alert>
            </Snackbar>
        </Grid>
    );
}

export default Login;
