import React from 'react'

import { useNavigate } from 'react-router-dom';

import { getAuth, signOut } from "firebase/auth";
// material-ui
import { Toolbar, Box, Typography, IconButton, Stack, Tooltip } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import Context from '../../../../context';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {

    // const { user, getLookupName, restartUser } = React.useContext(Context)
    // const navigate = useNavigate()

    

    // const auth = getAuth();
    // const signOutFromW = () => {
    //     restartUser()
    //     signOut(auth).then(() => {
    //         navigate('/login')
    //         // Sign-out successful.
    //     }).catch((error) => {
    //         // An error happened.
    //     });
    // }
    
    return (
        <Toolbar>
            <Stack direction='row' alignItems='start' justifyContent='space-between' sx={{ width: 1 }}>
                {/* <Typography variant="h5">{getLookupName("users", user.displayName)}</Typography>
                <Tooltip title="יציאה מהחשבון" arrow>
                    <IconButton size='small' onClick={signOutFromW}> <LogoutIcon /></IconButton>
                </Tooltip> */}
            </Stack>
        </Toolbar>
    );
};

export default DrawerHeader;
