import React from 'react';

// material-ui
import { Box, MenuList, MenuItem, ListItemText, ListItemIcon, Collapse, Typography } from '@mui/material';

import GroupsIcon from '@mui/icons-material/Groups';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';



// project imports
import SubItemMenu from './SubItemMenu';
import Context from '../../../../context';


const DrawerContent = () => {
    // const { user } = React.useContext(Context)

    const menuItemsJ = [
        // { title: "לוח הבקרה", to: "/", icon: <DashboardIcon color='primary' /> },
        { title: "דקות", to: "/manage-daka", icon: <GroupsIcon color='primary' /> },
        { title: "נמחקו", to: "/manage-daka/deleted", icon: <DeleteIcon color='primary' /> },
        { title: "תזכורות", to: "/manage-daka/tizkoret", icon: <AccessAlarmIcon color='primary' /> },

    ]



    const [active, setActive] = React.useState(null);

    //open array for the collapse menu
    const [selectedIndex, setSelectedIndex] = React.useState("")

    const ChooseItem = index => {
        console.log(index);
        if (selectedIndex === index) {
            setSelectedIndex("")
        } else {
            setSelectedIndex(index)
        }
    }

    const menuItems = menuItemsJ.map((menuItem, index) => {
        if (menuItem.items) {
            return <Box key={"f" + index}>
                <MenuItem
                    onClick={() => {
                        ChooseItem(index)
                    }}
                    sx={{
                        bgcolor: index === selectedIndex ? "primary.lighter" : null,
                        color: index === selectedIndex ? "white" : null
                    }}
                    disabled={menuItem.disabled}
                >
                    <ListItemIcon>
                        {menuItem.icon}
                    </ListItemIcon>
                    <ListItemText>
                        <Typography variant="subtitle2" color="primary">
                            {menuItem.title}
                            <span>&nbsp;{index === selectedIndex ? <span>&#9652;</span> : <span>&#9662;</span>}</span>
                        </Typography>
                    </ListItemText>

                </MenuItem>
                <Collapse in={index === selectedIndex} timeout="auto" unmountOnExit sx={{ bgcolor: "primary.lighter" }} >
                    {menuItem.items.map((subItem, subIndex) => {
                        return (
                            <MenuList component="div" disablePadding key={subIndex}>
                                <SubItemMenu title={subItem.title} path={subItem.to} active={active} setActive={setActive} isSubItem disabled={subItem.disabled} />
                            </MenuList>
                        )
                    })}
                </Collapse>
            </Box>
        }
        else {
            return <SubItemMenu key={"f" + index} title={menuItem.title}
                path={menuItem.to} active={active}
                setActive={setActive}
                icon={menuItem.icon}
                setSelectedIndex={setSelectedIndex}
                selectedIndex={index}
                disabled={menuItem.disabled} />
        }
    })

    return (
        <Box sx={{ width: 1 }}>
            <MenuList>
                {menuItems}
            </MenuList>
        </Box>
    )
}

export default DrawerContent;
