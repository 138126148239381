import React from "react";

import GenericTable from "../../components/GenericTable";
import { collection, doc, getDocs, orderBy, query, updateDoc, where, writeBatch } from "firebase/firestore";
import { db } from "../../firebase/firebase-sdk";
import { Button } from "@mui/material";
import SumData from "./SumData";
import Context from "../../context";
import DeleteIcon from "@mui/icons-material/Delete";


const ManageDaka = () => {

    const { snackbar, confirm } = React.useContext(Context)
    const [data, setData] = React.useState("loading")
    const [urlAudio, setUrlAudio] = React.useState("")
    const getData = () => {
        setData("loading")
        getDocs(query(collection(db, 'dakotHitbodedut'), where("isDeleted", "==", false), orderBy("createdAt", "desc"))).then(docs => {
            const docsData = docs.docs.map(doc => ({ ...doc.data(), id: doc.id, createdAt: doc.data().createdAt.toDate() }))

            console.log(docsData)
            setData(docsData)
        })

    }

    React.useEffect(() => {
        getData()
    }, [])

    React.useEffect(() => {
        if (urlAudio) {
            const baseUrlYemoths = "https://www.call2all.co.il/ym/api/DownloadFile?token=0747954726:212121&path=ivr2:"
            const audio = new Audio(baseUrlYemoths + urlAudio);
            audio.addEventListener("ended", () => {
                setUrlAudio("");
            });
            audio.play();
        }
    }, [urlAudio]);

    const columns = [
        { key: "dakot", label: "דקות", type: "text" },
        {
            label: "שם פרטי", cb: (row) => {
                if (row.firstName) return row.firstName
                if (row.recordFirstName) return <Button
                    size="small"
                    variant={urlAudio == row.recordFirstName ? "contained" : "outlined"}
                    onClick={() => setUrlAudio(row.recordFirstName)}>
                    {urlAudio == row.recordFirstName ? "משמיע" : "השמע"}
                </Button>
            }
        },
        {
            label: "שם משפחה", cb: (row) => {
                if (row.lastName) return row.lastName
                if (row.recordLastName) return <Button
                    size="small"
                    variant={urlAudio == row.recordLastName ? "contained" : "outlined"}
                    onClick={() => setUrlAudio(row.recordLastName)}>
                    {urlAudio == row.recordLastName ? "משמיע" : "השמע"}
                </Button>
            }
        },
        {
            label: "עיר", cb: (row) => {
                if (row.city) return row.city
                if (row.recordCity) return <Button
                    size="small"
                    variant={urlAudio == row.recordCity ? "contained" : "outlined"}
                    onClick={() => setUrlAudio(row.recordCity)}>
                    {urlAudio == row.recordCity ? "משמיע" : "השמע"}
                </Button>
            }
        },
        // { key: "lastName", label: "שם משפחה", type: "text" },
        { key: "phone", label: "טלפון", type: "text" },
        {
            label: "מייל", type: "text",
            cb: (row) => row.email != "null" ? row.email : ""
        },
        {
            label: "הערה", type: "text",
            cb: (row) => row.note != "null" ? row.note : ""
        },
        { key: "createdAt", label: "תאריך רישום", type: "datetime" },
        { key: "mekorHarshama", label: "מקור הרשמה", type: "text" },
        { key: "pirsum", label: "פרסום", type: "text" },
        {
            actionBtn: [{
                label: "מחיקה",
                icon: <DeleteIcon color="error" />,
                onClick: ({ row }) => {
                    confirm({
                        message: "האם אתה בטוח שברצונך למחוק את המידע?",
                        onConfirm: () => {
                            updateDoc(doc(db, 'dakotHitbodedut', row.id), { isDeleted: true }).then(() => {
                                getData()
                                snackbar("המידע נמחק בהצלחה")
                            }).catch((err) => {
                                snackbar("אירעה שגיאה")
                                console.log(err)
                            })
                        }
                    })

                }

            }]
        }
    ]
    return <GenericTable
        noData={" "}
        header={<SumData data={data} getData={getData} />}
        data={data == "loading" ? [] : data}
        loading={data === "loading"}
        columns={columns}
        innerPagination={true}
        
    />;
}

export default ManageDaka;