import { Box, Button, ButtonGroup, Divider, Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

const Lottery = ({ users }) => {
    const [winner, setWinner] = useState(null);
    const [isRunning, setIsRunning] = useState(false);

    const ids = React.useMemo(() => {
        const ids = [];
        for (let i = 0; i < users.length; i++) {
            for (let j = 0; j < users[i].dakot; j++) {
                ids.push(users[i].id);
            }
        }
        return ids;
    }, [users]);

    useEffect(() => {
        let intervalId;

        if (isRunning) {
            intervalId = setInterval(() => {
                const randomIndex = Math.floor(Math.random() * ids.length);
                setWinner(ids[randomIndex]);
            }, 10);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [isRunning, ids]);

    const winnerUser = users.find(user => user.id === winner)
    return (
        <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography variant="h2" color="primary.main">סה"כ כרטיסים: {ids?.length}</Typography>
            <Typography variant="h2" color="primary.main">משתתפים: {users.length}</Typography>

            <Button
                size='large'
                variant='contained'
                onClick={() => setIsRunning(!isRunning)}>
                {isRunning ? "עצור" : "התחל"}
            </Button>
            {winner && <h1>{winner}</h1>}
            {winner && !isRunning && <Stack spacing={1.5}>
<Divider/>
                <Typography variant="h2" color="success.darker">מזל טוב לזוכה:</Typography>
                {winnerUser.firstName && <Typography variant="h1" color="success.darker"><strong>{winnerUser.firstName} {winnerUser.lastName} - {winnerUser.city}</strong></Typography>}
                {winnerUser.recordFirstName && <ButtonGroup variant="outlined" color='success'>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                            const baseUrlYemoths = "https://www.call2all.co.il/ym/api/DownloadFile?token=0747954726:212121&path=ivr2:"
                            const audio = new Audio(baseUrlYemoths + winnerUser.recordFirstName).play();
                        }}>
                        השמע שם הזוכה
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                            const baseUrlYemoths = "https://www.call2all.co.il/ym/api/DownloadFile?token=0747954726:212121&path=ivr2:"
                            const audio = new Audio(baseUrlYemoths + winnerUser.recordLastName).play();
                        }}>
                        השמע שם משפחה
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                            const baseUrlYemoths = "https://www.call2all.co.il/ym/api/DownloadFile?token=0747954726:212121&path=ivr2:"
                            const audio = new Audio(baseUrlYemoths + winnerUser.recordCity).play();
                        }}>
                        השמע עיר
                    </Button>
                </ButtonGroup>}
                <Typography variant="h3" color="success.darker">טלפון: {winnerUser.phone}</Typography>
                {winnerUser.email && winnerUser.email != "null" && <Typography variant="h3" color="success.darker">מייל: {winnerUser.email}</Typography>}
                <Typography variant="h3" color="success.darker">דקות: {winnerUser.dakot}</Typography>
                <Typography variant="h3" color="success.darker">מקור הרשמה: {winnerUser.mekorHarshama}</Typography>
                {winnerUser.note && winnerUser.note != "null" && <Typography variant="h3" color="success.darker">הערה: {winnerUser.note}</Typography>}
            </Stack>}
        </Stack>
    );
};

export default Lottery;
