import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import NedarimDaka from "../pages/NedarimDaka";
import Login from "../pages/Login";
import ManageDaka from "../pages/ManageDaka";
import Layout from "../layout/Layout";
import ManageDakaDeleted from "../pages/ManageDakaDeleted";
import Tizkoret from "../pages/Tizkoret";

const ProjectRoutes = () => {

    return (
        <BrowserRouter>
            <Routes>

                <Route path="/login" element={<Login />}></Route>
                <Route path="/manage-daka" element={<Layout />}>
                    <Route path="/manage-daka" element={<ManageDaka />} />
                    <Route path="/manage-daka/deleted" element={<ManageDakaDeleted />} />
                    <Route path="/manage-daka/tizkoret" element={<Tizkoret />} />
                </Route>
                <Route path="/" element={<NedarimDaka />}></Route>
                <Route path="*" element={<NedarimDaka />}></Route>
            </Routes>

        </BrowserRouter>
        // <TwentyFourHours />
        // <div>fff</div>
        // <Routes>
        //     <Route path="/a" element={<div>123</div>} />
        //     <Route path="/24hours" element={<div>fff</div>} />
        // </Routes>
    );
};

export default ProjectRoutes;