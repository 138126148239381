import React from "react";

import GenericTable from "../../components/GenericTable";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-sdk";
import SumData from "./SumData";


const Tizkoret = () => {

    const [data, setData] = React.useState("loading")
    const [urlAudio, setUrlAudio] = React.useState("")
    const getData = () => {
        getDocs(query(collection(db, 'tizkoret'), where("shaatTzkoret", "not-in", ["null", 0, "0"]), orderBy("shaatTzkoret"))).then(docs => {
            const docsData = docs.docs.map(doc => ({ ...doc.data(), phone: doc.id, }))
            setData(docsData)
        })

    }

    React.useEffect(() => {
        getData()
    }, [])


    const columns = [
        { key: "phone", label: "טלפון", type: "text" },
        { key: "shaatTzkoret", label: "שעת תזכורת", type: "text" },
        { key: "tizkoretType", label: "סוג תזכורת", type: "text" },
    ]
    return <GenericTable
        header={<SumData data={data} />}
        data={data == "loading" ? [] : data}
        loading={data === "loading"}
        columns={columns}
        innerPagination={true}
    />;
}

export default Tizkoret;