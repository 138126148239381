import React from 'react';

import ThemeCustomization from './themes';

import ProjectRoutes from "./routes";

import ContextProvider from "./context/ContextProvider";


function App() {
  return (
    <ContextProvider>
      <ThemeCustomization>
        <ProjectRoutes />
      </ThemeCustomization>
    </ContextProvider>
  );
}

export default App;

//   <QueryClientProvider client={queryClient}>
//   {/* <ReactQueryDevtools initialIsOpen={false} /> */}
//   <ContextProvider>
// <ThemeCustomization>
//   <ProjectRoutes />
// </ThemeCustomization>
//   </ContextProvider>
// </QueryClientProvider>