import React from "react";


const NedarimDaka = () => {

    return (
        <iframe src="https://matara.pro/nedarimplus/Forms/2302.html" style={{
            width: "100%",
            height: "100vh",
            border: "none",
            margin: "0",
            padding: "0",
            overflow: "hidden",
        }} frameborder="0" title="nedarim"></iframe>

    );
};

export default NedarimDaka;